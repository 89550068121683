// Check if garantie is on current page
if (document.body.contains(document.querySelector('.garantie'))) {
  let currentStep = 0;

  const wrapperElem = document.querySelector('.garantie__content__wrapper');
  const contentElems = document.querySelectorAll('.garantie__content__item');
  const navElems = document.querySelectorAll('.garantie__nav__item');

  // Navigation
  const startElem = document.querySelector('.js-garantie-start');
  startElem.addEventListener('click', () => changeStep('next'));

  const prevBtn = document.querySelector('.js-garantie-prev');
  prevBtn.addEventListener('click', () => changeStep('prev'));

  const nextBtn = document.querySelector('.js-garantie-next');
  nextBtn.addEventListener('click', () => changeStep('next'));

  // Animation
  const truckElems = document.querySelectorAll('.figure__animated-truck');

  function changeStep(type = null) {
    // Animate truck elems
    if (type == 'next' && currentStep < truckElems.length) {
      currentStep++;

      const nextElem = truckElems[currentStep - 1];
      nextElem.classList.add('figure__animated-truck--ride-in');
    } else if (type == 'prev' && currentStep > 0) {
      currentStep--;

      const prevElem = truckElems[currentStep];
      prevElem.classList.remove('figure__animated-truck--ride-in');
    }

    // Hide begin button
    if (currentStep > 0) {
      startElem.classList.add('garantie__slider__start--hidden');
    } else {
      startElem.classList.remove('garantie__slider__start--hidden');
    }

    // Navigation circles
    for (let i = 0; i < navElems.length; i++) {
      const elem = navElems[i];
      elem.classList.remove('garantie__nav__item--active')
      if (i === currentStep) {
        elem.classList.add('garantie__nav__item--active')
      }
    }

    // Content animation
    if (currentStep > 1) {
      const elemStyles = window.getComputedStyle(document.querySelector('.garantie__content'), null)
      const width = (parseInt(elemStyles.getPropertyValue('width')) - parseInt(elemStyles.getPropertyValue('padding-left'), 10)) * (currentStep - 1);

      wrapperElem.style.transform = `translate(-${width}px, 0)`;
    } else {
      wrapperElem.removeAttribute('style');
    }
  }
}


