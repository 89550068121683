window.addEventListener('scroll', () => {
  const bodyEl = document.querySelector('body');
  const scrollPos = Math.abs(bodyEl.getBoundingClientRect().top);
  const headerEl = document.querySelector('.js-header');

  if (scrollPos > 75) {
    headerEl.classList.add('sticky');
    bodyEl.classList.add('sticky');
  } else {
    headerEl.classList.remove('sticky');
    bodyEl.classList.remove('sticky');
  }
});
