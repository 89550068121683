// Check if vacatures is on current page
const vacatures = document.querySelector('.vacatures');
if (document.body.contains(vacatures)) {
  const vacatureListItems = document.querySelectorAll('.vacatures__list__item');
  const vacatureContentItems = document.querySelectorAll('.vacatures__content');

  let activeElem = null;

  for (let i = 0; i < vacatureListItems.length; i++) {
    const elem = vacatureListItems[i];

    elem.addEventListener('click', (event, index) => {
      // De-activate current items, if not allready active
      if (activeElem !== i) {
        activeElem = i;

        for (let i = 0; i < vacatureListItems.length; i++) {
          const listItem = vacatureListItems[i];
          listItem.classList.remove('vacatures__list__item--active');
        }

        for (let i = 0; i < vacatureContentItems.length; i++) {
          const contentItem = vacatureContentItems[i];
          contentItem.classList.remove('vacatures__content--active');
        }
      }

      // Activate clicked item
      elem.classList.toggle('vacatures__list__item--active');

      const contentElem = elem.nextElementSibling;
      contentElem.classList.toggle('vacatures__content--active');

      // If greater than mobile
      if (window.outerWidth > 700) {
        if (contentElem.classList.contains('vacatures__content--active')) {
          const vacatureStyles = window.getComputedStyle(vacatures);
          vacatures.style.height = `${contentElem.offsetHeight + parseInt(vacatureStyles.paddingTop, 10) + parseInt(vacatureStyles.paddingBottom, 10) + 31}px`; // 31 = title height
        } else {
          vacatures.removeAttribute('style');
        }
      }
    });
  }
}